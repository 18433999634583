<template>
    <modal
        class="report-modal"
        :adaptive="true"
        name="report-modal"
        height="auto"
        width="400"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('report-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <fullscreen-loader v-if="isLoading" />
        <div class="content">
            <h1>Report</h1>
            <div v-if="!shouldShowDescriptionBox" class="reports-types">
                <ul>
                    <li v-for="reportType in reportsTypes" :key="reportType.id">
                        <label>
                            {{ reportType.name }}
                            <input
                                v-model="selectedReportId"
                                :value="reportType.id"
                                type="radio"
                                @input="handleDescriptionBox(reportType)"
                            >
                            <img
                                v-if="reportType.requires_description"
                                class="chevron"
                                src="@assets/img/icons/left-chevron.svg"
                                width="10px"
                            >
                            <span v-else />
                        </label>
                    </li>
                </ul>
                <span>Not sure if something is breaking the rules? Read the<br><a target="_blank" href="/legal/terms-of-service#6">Content Creation Policies</a></span>
            </div>
            <div v-else class="description-content">
                <h3>{{ selectedReport.reportDescription }}</h3>
                <textarea v-model="description" rows="6" placeholder="Tell us why" />
            </div>
            <button
                class="btn btn-primary submit-btn"
                :disabled="!allowSubmit"
                @click="submitReport"
            >
                Submit
            </button>
        </div>
    </modal>
</template>

<script>
/* eslint-disable no-prototype-builtins */
export default {
    name: "ReportModal",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue")
    },
    data() {
        return {
            entityId: null,
            entityName: "",
            selectedReportId: "",
            description: "",
            reportsTypes: [],
            shouldShowDescriptionBox: false,
            isLoading: false
        }
    },
    computed: {
        entityNamespace() {
            if (this.entityName === "memo") {
                return "Gewaer\\Models\\Memos";
            }

            if (this.entityName === "comment") {
                return "Gewaer\\Models\\Comments"
            }

            return "";
        },
        selectedReport() {
            if (!this.selectedReportId) {
                return "";
            }

            const selectedReport = this.reportsTypes.find(reportType => reportType.id === this.selectedReportId);
            return {
                entity_namespace: this.entityNamespace,
                entity_id: this.entityId,
                report_type_id: selectedReport.id,
                title: selectedReport.name,
                description: this.description,
                reportDescription: selectedReport.description,
                requires_description: selectedReport.requires_description
            }
        },
        allowSubmit() {
            if (!this.selectedReport) {
                return false;
            }

            if (this.selectedReport.requires_description && !this.description) {
                return false;
            }

            return true;
        }
    },
    methods: {
        handleDescriptionBox(reportType) {
            if (reportType.requires_description) {
                this.shouldShowDescriptionBox = true;
            }
        },
        beforeOpen(event) {
            if (event.params?.entityId && event.params?.entityName) {
                this.entityId = event.params.entityId;
                this.entityName = event.params.entityName;
                this.getReportsTypes();
            } else {
                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: "Can't report, try again later",
                    duration: 3000
                });

                event.cancel();
            }
        },
        beforeClose() {
            this.entityId = null;
            this.entityName = "";
            this.selectedReportId = "";
            this.description = "";
            this.shouldShowDescriptionBox = false;
        },
        getReportsTypes() {
            this.isLoading = true;

            axiosV2.get(`/report-types?q=(entity_namespace:${this.entityNamespace})`).then(({ data }) => {
                this.reportsTypes = data;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        submitReport() {
            this.isLoading = true;

            axiosV2.post("/reports", this.selectedReport).then(() => {
                this.$emit("reported-entity", this.entityId, this.entityName);
                this.$modal.hide("report-modal");
                this.$modal.show("thanks-modal");
            }).finally(() => {
                this.isLoading = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.report-modal {
    z-index: 99999;

    /deep/ .loader {
        position: absolute;
    }

    .close-modal {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal{
        background-color: #1C1C1E;
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 35px 45px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                text-align: center;
                font-weight: 600;
                font-size: 26px;
                line-height: 20px;
                margin-bottom: 35px;
            }

            .reports-types {
                padding-left: 0;
                margin-bottom: 0;
                list-style: none;

                ul {
                    padding-left: 16px;

                    li {
                        padding: 14px 0;
                        font-size: 16px;

                        label {
                            display: flex;
                            justify-content: space-between;
                            position: relative;
                            cursor: pointer;
                            margin-bottom: 0;
                            align-items: center;

                            input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: none;
                            }

                            .chevron {
                                transform: rotate(180deg) translateX(3px);
                            }

                            span {
                                width: 18px;
                                height: 18px;
                                border: 1px solid white;
                                border-radius: 100%;
                                position: relative;
                                flex-shrink: 0;
                            }

                            input:checked {
                                + span::after {
                                    content: "";
                                    position: absolute;
                                    width: 7px;
                                    height: 7px;
                                    background-color: white;
                                    border-radius: 100%;
                                    transform: translate(-50%, -50%);
                                    top: 50%;
                                    left: 50%;
                                }
                            }
                        }
                    }
                }

                > span {
                    text-align: center;
                    display: block;
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 30px;

                    a {
                        color: $primary-color;
                    }
                }
            }

            .description-content {
                h3 {
                    font-size: 16px;
                    color: rgba(235, 235, 245, 0.6);
                    padding: 0 10px;
                    text-align: center;
                    margin-bottom: 30px;
                }

                textarea {
                    width: 100%;
                    background-color:  #2C2C2E;
                    border-radius: 12px;
                    border: 0;
                    padding: 12px;
                    color: rgba(235, 235, 245, 0.6);
                    font-size: 15px;

                    &:focus-visible {
                        outline: 0;
                    }
                }
            }

            .submit-btn {
                margin-top: 20px;
            }
        }
    }
}
</style>
